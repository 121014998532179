import { Environment } from './environment.interface';

export const environment: Environment = {
  production: true,

  backendUrl: 'https://api.thepineapp.com',

  logging: {
    enabled: true,
    dsn: 'https://398820764c6c4310af7d0101f7080b41@o1054664.ingest.sentry.io/6042085',
  },

  tracking: {
    enabled: true,
  },

  social: {
    emailUrl: 'mailto:info@thepineapp.com',
    supportEmailUrl: 'mailto:support@thepineapp.com',
  },

  deepLinkUrl: 'pine-app://app-launch.thepineapp.com',

  appDownload: {
    iOS: 'https://apps.apple.com/app/apple-store/id1593070808?pt=123407413&ct=Website%20download%20button&mt=8',
    android: '',
  },
};
