import { Inject, Injectable } from '@angular/core';
import { Environment } from '../../../../environments/environment.interface';
import { ENVIRONMENT } from '../injection-tokens';
import { Angulartics2, Angulartics2GoogleGlobalSiteTag, Angulartics2Segment } from 'angulartics2';
import { MessageBusService } from '../message-bus/message-bus.service';
import { TrackingEventCategory, TrackingEvents } from './tracking-events';

@Injectable()
export class TrackingService {
  constructor(@Inject(ENVIRONMENT) private _environment: Environment,
              private _messageBus: MessageBusService,
              private _angulartics2: Angulartics2,
              private _googleTagManagerAnalytics: Angulartics2GoogleGlobalSiteTag,
              private _angulartics2Segment: Angulartics2Segment) {
  }

  public initialize() {
    if (!this._environment?.tracking?.enabled) {
      return;
    }

    this._initializeProviders();

    for (const trackingEvent of TrackingEvents) {
      this._messageBus.subscribe(trackingEvent.triggerMessageType)
        .subscribe(message => {
          let eventName: string;

          if (typeof (trackingEvent.eventName) === 'function') {
            eventName = trackingEvent.eventName(message.payload);
          } else {
            eventName = <string>trackingEvent.eventName;
          }

          this.trackEvent(eventName, trackingEvent.eventCategory, trackingEvent.passPayload ? message.payload : undefined);
        });
    }
  }

  public trackEvent(name: string, category: TrackingEventCategory, properties?: any) {
    if (!this._environment?.tracking?.enabled) {
      return;
    }

    this._angulartics2.eventTrack.next({
      action: name,
      properties: {
        category: category,
        ...properties,
      },
    });
  }

  private _initializeProviders() {
    this._angulartics2Segment.startTracking();
    this._googleTagManagerAnalytics.startTracking();
  }
}
