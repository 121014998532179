import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { LoggerService } from './logger.service';
import { WINDOW } from '../injection-tokens';
import { CoolLocalStorage } from '@angular-cool/storage';

export const CHUNK_LOAD_ERROR_MESSAGE_REGEX = /Loading chunk [\d]+ failed/;
export const CHUNK_RELOAD_TIME_STORAGE_KEY = 'last-chunk-reload';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(@Inject(WINDOW) private _window: Window,
              private _localStorage: CoolLocalStorage,
              private _logger: LoggerService) {
  }

  handleError(err: any): void {
    if(CHUNK_LOAD_ERROR_MESSAGE_REGEX.test(err.message)) {
      const lastReloadTime = this._localStorage.getItem(CHUNK_RELOAD_TIME_STORAGE_KEY);

      if (!lastReloadTime ||  (new Date().getTime() - new Date(lastReloadTime).getTime()) > 60000) {
        this._localStorage.setItem(CHUNK_RELOAD_TIME_STORAGE_KEY, new Date().toISOString());

        this._window.location.reload();

        return;
      }
    }

    this._logger.error(err.originalError || err.error || err);
  }
}
