import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { WINDOW } from './common/services/injection-tokens';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _changeDetector: ChangeDetectorRef,
    @Inject(WINDOW) private _window: Window,
  ) {

  }

  public showHeader = false;

  public ngOnInit() {
    this._activatedRoute.data.subscribe(data => {
      this.showHeader = !!data.hideHeader;
    });

    this._router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this._window.scrollTo(0, 0);

        const allRouteList = this._traverseActivatedRoutes(this._activatedRoute.snapshot);

        this.showHeader = !allRouteList.some(_ => !!_.data?.hideHeader);

        this._changeDetector.markForCheck();
      });
  }

  private _traverseActivatedRoutes(snapshot: ActivatedRouteSnapshot): ActivatedRouteSnapshot[] {
    let result = [];

    result.push(snapshot);

    if (snapshot.children?.length) {
      for (const child of snapshot.children) {
        result = result.concat(this._traverseActivatedRoutes(child));
      }
    }

    return result;
  }
}
