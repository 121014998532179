import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { environment } from '../../environments/environment';
import { AppCommonModule } from '../common/common.module';
import { GlobalErrorHandler } from '../common/services/logger/global-error-handler';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { StartupService, startupServiceFactory } from './shared/services/startup/startup.service';
import { TranslationService } from './shared/services/translation/translation.service';
import { faFacebookF, faInstagram, faTiktok, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faGlobe } from '@fortawesome/free-solid-svg-icons';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    NotFoundComponent,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    NotFoundComponent,
  ],
  imports: [
    AppCommonModule,
  ],
  providers: [
    StartupService,
    TranslationService,

    { provide: ErrorHandler, useClass: environment.logging?.enabled ? GlobalErrorHandler : ErrorHandler },
    { provide: APP_INITIALIZER, useFactory: startupServiceFactory, deps: [StartupService], multi: true },
  ],
})
export class AppCoreModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faGlobe,
      faFacebookF,
      faInstagram,
      faTiktok,
      faYoutube,
      faTwitter,
      faEnvelope,
    );
  }
}
