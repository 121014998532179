import { FailedLoginModule } from '../../failed-login/failed-login.module';

export const RouteLocations = {
  Home: 'home',
  HomeChildren: {
    Landing: '',
  },

  Login: 'login',
  LoginChildren: {
    Validate: 'validate',
    CancelDeletion: 'cancel-deletion',
    ResetPassword: 'reset-password',
  },

  About: 'about',
  AboutChildren: {
    TermsAndConditions: 'terms',
    PrivacyPolicy: 'privacy',
  },

  Landing: 'landing',
  LandingChildren: {},

  Maintenance: 'maintenance',
  MaintenanceChildren: {
    MaintenancePage: '',
  },

  FailedLogin: 'login-failed',
  FailedLoginChildren: {
    FailedLoginPage: '',
  },

  FAQ: 'faq',
  FAQChildren: {
    FAQPage: '',
  },

  NotFound: 'not-found',
};
