import { Injectable } from '@angular/core';
import { CoolHttp } from '@angular-cool/http';

@Injectable()
export class AuthenticationService {
  constructor(private _http: CoolHttp) {

  }

  public async validateEmailAsync(token: string) {
    await this._http.postAsync(`api/authentications/local/validate`, {
      validationToken: token,
    });
  }

  public async resetPasswordAsync({ newPassword, email, token }: { newPassword: string; email: string; token: any }) {
    await this._http.postAsync(`api/authentications/local/password-reset/validate`, {
      email: email,
      newPassword: newPassword,
      token: token,
    });
  }

  public async sendForgotPasswordEmailAsync(email: string) {
    await this._http.postAsync(`api/authentications/local/password-reset`, {
      email: email,
    });
  }

  public async resendValidationEmailAsync() {
    await this._http.postAsync(`api/authentications/local/validate/again`);
  }

  public async cancelUserDeletionAsync(identifier: string) {
    await this._http.deleteAsync(`api/users/deletion-requests/${identifier}`);
  }
}
