<div class="h-100 m-auto" style="max-width: 2000px">
  <div style="min-height: 100%" class="d-flex flex-column">
    <div id="header-container" *ngIf="showHeader">
      <app-header></app-header>
    </div>

    <div id="page-container">
      <router-outlet></router-outlet>
    </div>
  </div>

  <app-footer></app-footer>
</div>
