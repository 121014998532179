import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ENVIRONMENT, WINDOW } from './services/injection-tokens';
import { environment } from '../../environments/environment';
import { RouteNavigationService } from './routing/route-navigation.service';
import { LogoComponent } from './components/logo/logo.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoggerService } from './services/logger/logger.service';
import { TrackingService } from './services/tracking/tracking.service';
import { AuthenticationService } from './services/authentication.service';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { Angulartics2Module } from 'angulartics2';
import { MessageBusService } from './services/message-bus/message-bus.service';
import { CoolHttpModule } from '@angular-cool/http';
import { CoolStorageModule } from '@angular-cool/storage';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CoolLoadingButtonModule } from '@angular-cool/loading-button';
import { DateProvider } from './services/date.provider';
import { MatMenuModule } from '@angular/material/menu';
import { AppDownloadAppButtonComponent } from './components/download-app-button/app-download-app-button.component';
import { AppOpenAppButtonComponent } from './components/open-app-button/app-open-app-button.component';
import { PricingDetailsComponent } from './components/pricing-details/pricing-details.component';

@NgModule({
  declarations: [
    LogoComponent,
    LoadingIndicatorComponent,
    AppDownloadAppButtonComponent,
    AppOpenAppButtonComponent,
    PricingDetailsComponent,
  ],
  imports: [
    CommonModule,

    FormsModule,

    RouterModule,
    FontAwesomeModule,

    CoolLoadingButtonModule,
    CoolHttpModule,
    CoolStorageModule,
    Angulartics2Module.forRoot(),

    TranslateModule.forChild(),

    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatProgressSpinnerModule,

    MatFormFieldModule,
    MatInputModule,
  ],
  exports: [
    CommonModule,

    FormsModule,

    RouterModule,

    LoadingIndicatorComponent,
    LogoComponent,
    AppDownloadAppButtonComponent,
    AppOpenAppButtonComponent,
    PricingDetailsComponent,

    FontAwesomeModule,

    Angulartics2Module,

    CoolLoadingButtonModule,
    CoolHttpModule,
    CoolStorageModule,

    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatMenuModule,
    MatProgressSpinnerModule,

    MatFormFieldModule,
    MatInputModule,

    TranslateModule,
  ],
})
export class AppCommonModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(

    );
  }

  public static forRoot(): ModuleWithProviders<AppCommonModule> {
    return {
      ngModule: AppCommonModule,
      providers: [
        LoggerService,
        TrackingService,
        RouteNavigationService,
        AuthenticationService,

        MessageBusService,

        DateProvider,

        ...CoolHttpModule.forRoot().providers as Provider[],
        ...CoolStorageModule.forRoot().providers as Provider[],

        { provide: ENVIRONMENT, useValue: environment },
        { provide: WINDOW, useValue: window },
      ],
    };
  }
}
