import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteLocations } from './route-locations.enum';

@Injectable()
export class RouteNavigationService {
  constructor(private _router: Router) {
  }

  public async goToNotFoundAsync() {
    await this._router.navigateByUrl(RouteNavigationService._buildUrl(
      RouteLocations.NotFound,
    ));
  }

  public getUrlForLanding(keepOpened = false) {
    let url = RouteNavigationService._buildUrl(
      RouteLocations.Home,
      RouteLocations.HomeChildren.Landing,
    );

    if (keepOpened) {
      url += '?keep';
    }

    return url;
  }

  public getUrlForPrivacyPolicy() {
    return RouteNavigationService._buildUrl(
      RouteLocations.About,
      RouteLocations.AboutChildren.PrivacyPolicy,
    );
  }

  public getUrlForTermsAndConditions() {
    return RouteNavigationService._buildUrl(
      RouteLocations.About,
      RouteLocations.AboutChildren.TermsAndConditions,
    );
  }

  public getUrlForFAQ() {
    return RouteNavigationService._buildUrl(
      RouteLocations.FAQ,
    );
  }

  private static _buildUrl(...parts: string[]): string {
    return '/' + parts.filter(_ => !!_).join('/');
  }

  public async overwriteQueryParamsAsync(
    activatedRoute: ActivatedRoute,
    queryParams: any,
    replaceHistory: boolean = true,
  ) {
    await this._router.navigate(
      [],
      {
        relativeTo: activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge',
        replaceUrl: replaceHistory,
      });
  }
}
