<footer class="p-3">
  <div class="row align-items-center">
    <div class="col-12 col-md-3 order-3 order-md-0 py-2 text-center text-md-start">
        &copy; {{ year }} Pine All rights reserved.
    </div>

    <div class="col-12 col-md-4 order-0 order-md-1 py-2 text-center text-md-start">
      <a class="footer-link m-3 d-block d-md-inline-block" [routerLink]="termsUrl">{{ 'Footer.Terms' | translate }}</a>

      <a class="footer-link m-3 d-block d-md-inline-block" [routerLink]="privacyUrl">{{ 'Footer.Privacy' | translate }}</a>

      <a class="footer-link m-3 d-block d-md-inline-block" [routerLink]="faqUrl" *ngIf="faqUrl">{{ 'Footer.FAQ' | translate }}</a>
    </div>

    <div class="col-12 col-md-5 order-1 order-md-2 py-2 text-center text-md-start">
      <a class="brand-button d-inline-flex m-2" href="https://www.facebook.com/thepinememories" target="_blank">
        <fa-icon [icon]="['fab', 'facebook-f']"></fa-icon>
      </a>

      <a class="brand-button d-inline-flex m-2" href="https://www.instagram.com/pinememories/" target="_blank">
        <fa-icon [icon]="['fab', 'instagram']"></fa-icon>
      </a>

      <a class="brand-button d-inline-flex m-2" href="https://www.tiktok.com/@pinememories?lang=en" target="_blank">
        <fa-icon [icon]="['fab', 'tiktok']"></fa-icon>
      </a>

      <a class="brand-button d-inline-flex m-2" href="https://twitter.com/PineMemories" target="_blank">
        <fa-icon [icon]="['fab', 'twitter']"></fa-icon>
      </a>

      <a class="brand-button d-inline-flex m-2" href="https://www.youtube.com/channel/UChVGC6uMcLrbqtK0wKKIgVw/featured" target="_blank">
        <fa-icon [icon]="['fab', 'youtube']"></fa-icon>
      </a>

      <a *ngIf="contactUrl" class="brand-button d-inline-flex m-2" [attr.href]="contactUrl" target="_blank">
        <fa-icon icon="envelope"></fa-icon>
      </a>
    </div>
  </div>
</footer>
